module directives {
    export module applicationcore {
        interface IAddressScope extends ng.IScope {
            transactionId: number;
            transactionType: number;
            ngDisabled: boolean;
            showCountry: boolean;
            hidePostal: boolean;
            showAddress(): void;
        }

        export class addressModalPopupDirective implements ng.IDirective
        {
            template = `<p class="input-group-sm">
                            <button type="button" class="btn btn-primary" ng-click="showAddress()" ng-disabled="ngDisabled">Address</button>
                        </p>`;
            scope = {
                transactionId: "=",
                transactionType: "=",
                ngDisabled: "=",
                showCountry: "=",
                hidePostal: "="
            }
            
            address: interfaces.applicationcore.IAddress;

            constructor(private generalService: interfaces.applicationcore.IGeneralService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public addressService: interfaces.applicationcore.IAddressService,
                public countryService: interfaces.applicationcore.ICountryService,
                public $uibModal: ng.ui.bootstrap.IModalService,
            ) {}

            link = ($scope: IAddressScope, $element: ng.IAugmentedJQuery) =>
            {
                $scope.showAddress = () => {
                    //Get the address.
                    this.addressService.getAddressDetails($scope.transactionId, $scope.transactionType).query({
                    }, (result: interfaces.applicationcore.IAddress) => {
                            this.address = result[0];
                            this.showAddress($scope.transactionType, $scope.showCountry, $scope.hidePostal);
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                }
            }

            //Display Addresses
            showAddress(transactionType: number, showCountry: boolean, hidePostal:boolean) {
                this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="ConsignmentDetail">
                                <div class="gts-padding">
                                    <fieldset id="Address">
                                        <div class="col-md-12">
                                            <div class="row vertical-align">
                                                <div class="col-md-4">
                                                    <req></req>
                                                    <label>Name</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': companyName.$invalid}">
                                                        <input class="form-control" type="text" ng-model="address.CompanyName" required
                                                               aria-label="Company Name" name="companyName">
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="row vertical-align">
                                                <div class="col-md-10">
                                                    <opt></opt>
                                                    <label>Street Address</label>
                                                </div>
                                                <div class="col-md-10" ng-if="!hidePostal">
                                                    <opt></opt>
                                                    <label>Postal Address</label>
                                                </div>
                                            </div>
                                            <!-- Line 1 -->
                                            <div class="row vertical-align">
                                                <div class="col-md-4">
                                                    <req></req>
                                                    <label>Line 1: </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': lineOne.$invalid}">
                                                        <input class="form-control" type="text" ng-model="address.StreetAddressLine1" required
                                                               aria-label="Line One" name="lineOne">
                                                    </p>
                                                </div>
                                                <div class="col-md-4" ng-if="!hidePostal">
                                                    <req></req>
                                                    <label>Line 1: </label>
                                                </div>
                                                <div class="col-md-6"  ng-if="!hidePostal">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': lineOnePost.$invalid}">
                                                        <input class="form-control" type="text" ng-model="address.PostAddressLine1" ng-disabled="(transactionType === 6) || (transactionType === 7) || (transactionType === 8)" required
                                                               aria-label="Line One Post" name="lineOnePost">
                                                    </p>
                                                </div>
                                            </div>
                                            <!-- Line 2 -->
                                            <div class="row vertical-align">
                                                <div class="col-md-4">
                                                    <opt></opt>
                                                    <label>Line 2: </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': lineTwo.$invalid}">
                                                        <input class="form-control" type="text" ng-model="address.StreetAddressLine2" required
                                                               aria-label="Line Two" name="lineTwo">
                                                    </p>
                                                </div>
                                                <div class="col-md-4"  ng-if="!hidePostal">
                                                    <opt></opt>
                                                    <label>Line 2: </label>
                                                </div>
                                                <div class="col-md-6"  ng-if="!hidePostal">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': lineTwoPost.$invalid}">
                                                        <input class="form-control" type="text" ng-model="address.PostAddressLine2" ng-disabled="(transactionType === 6) || (transactionType === 7) || (transactionType === 8)" required
                                                               aria-label="Line Two Post" name="lineTwoPost">
                                                    </p>
                                                </div>
                                            </div>
                                            <!-- Line 3 -->
                                            <div class="row vertical-align">
                                                <div class="col-md-4">
                                                    <opt></opt>
                                                    <label>Line 3: </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': lineThree.$invalid}">
                                                        <input class="form-control" type="text" ng-model="address.StreetAddressLine3" required
                                                               aria-label="Line Three" name="lineThree">
                                                    </p>
                                                </div>
                                                <div class="col-md-4"  ng-if="!hidePostal">
                                                    <opt></opt>
                                                    <label>Line 3: </label>
                                                </div>
                                                <div class="col-md-6" ng-if="!hidePostal"> 
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': lineThreePost.$invalid}">
                                                        <input class="form-control" type="text" ng-model="address.PostAddressLine3" ng-disabled="(transactionType === 6) || (transactionType === 7) || (transactionType === 8)" required
                                                               aria-label="Line Three Post" name="lineThreePost">
                                                    </p>
                                                </div>
                                            </div>
                                            <!-- Line 4 -->
                                            <div class="row vertical-align">
                                                <div class="col-md-4">
                                                    <opt></opt>
                                                    <label>Line 4: </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': lineFour.$invalid}">
                                                        <input class="form-control" type="text" ng-model="address.StreetAddressLine4" required
                                                               aria-label="Line Four" name="lineFour">
                                                    </p>
                                                </div>
                                                <div class="col-md-4"  ng-if="!hidePostal">
                                                    <opt></opt>
                                                    <label>Line 4: </label>
                                                </div>
                                                <div class="col-md-6"  ng-if="!hidePostal">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': lineFourPost.$invalid}">
                                                        <input class="form-control" type="text" ng-model="address.PostAddressLine4" ng-disabled="(transactionType === 6) || (transactionType === 7) || (transactionType === 8)" required
                                                               aria-label="Line Four Post" name="lineFourPost">
                                                    </p>
                                                </div>
                                            </div>
                                            <!-- Postal Code -->
                                            <div class="row vertical-align">
                                                <div class="col-md-4">
                                                    <opt></opt>
                                                    <label>Postal: </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': postalCodeStreet.$invalid}">
                                                        <input class="form-control" type="text" ng-model="address.StreetAddressPostal" required
                                                               aria-label="Postal Code Address" name="postalCodeStreet">
                                                    </p>
                                                </div>
                                                <div class="col-md-4" ng-if="!hidePostal">
                                                    <opt></opt>
                                                    <label>Postal: </label>
                                                </div>
                                                <div class="col-md-6" ng-if="!hidePostal">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': postalCodePost.$invalid}">
                                                        <input class="form-control" type="text" ng-model="address.PostAddressPostal" ng-disabled="(transactionType === 6) || (transactionType === 7) || (transactionType === 8)" required
                                                               aria-label="Postal Code Post" name="postalCodePost">
                                                    </p>
                                                </div>
                                            </div>
                                             <div class="row vertical-align" ng-if="showCountry">
                                                <div class="col-md-4">
                                                    <opt></opt>
                                                    <label>Country: </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': country.$invalid}">
                                                        <gts-dropdown ng-model="address.Country" load-data="loadCountries()"></gts-dropdown>
                                                    </p>
                                                </div>
                                                <div class="col-md-4" ng-if="!hidePostal"><opt></opt></div>
                                                <div class="col-md-6" ng-if="!hidePostal"><opt></opt></div>
                                            </div>
                                            <div class="pull-right">
                                                <div class="col-md-12">
                                                    <button type="button" class="btn btn-primary" ng-click="ok()">
                                                        Close
                                                    </button>
                                                    <button type="button" class="btn btn-primary" ng-click="submit()">
                                                        Submit &amp; Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {

                        $scope.ok = () => {
                            $uibModalInstance.dismiss();
                        }

                        $scope.address = this.address;
                        $scope.transactionType = transactionType;
                        $scope.showCountry = showCountry;
                        $scope.hidePostal = hidePostal;

                        $scope.submit = () => {
                            this.submit();
                            $scope.ok();
                        }

                        $scope.loadCountries = () => {
                            return this.countryService.getDropdownList().query({
                            },
                                (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                                }, (failureData) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                }).$promise;
                        }
                    },
                    size: "lg",
                    resolve: {
                    }
                });
            }

            submit()
            {
                //Set the address.
                this.addressService.setAddressDetails().save(
                    this.address
                , () => {
                    //Result
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                });
            }
            
            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, classificationValueService, addressService, countryService, $uibModal) => new addressModalPopupDirective(generalService, classificationValueService, addressService, countryService, $uibModal);
                directive.$inject = ["generalService", "classificationValueService", "addressService", "countryService", "$uibModal"];

                return directive;
            }
        }

        angular.module("app").directive("gtsAddressModalPopup", addressModalPopupDirective.factory());
    }
}